export enum CustomMatIcon {
  CustomArrowBackIcon = `<svg viewBox="0 0 40 40" fill="none">
    <path d="M34.1654 19.9997H9.9987M19.9987 8.33301L8.33203 19.9997L19.9987 31.6663" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
  CustomCarIcon = `<svg viewBox="0 0 24 24" fill="none">
    <path d="M15.51 2.83008H8.49C6 2.83008 5.45 4.07008 5.13 5.59008L4 11.0001H20L18.87 5.59008C18.55 4.07008 18 2.83008 15.51 2.83008Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.9888 19.82C22.0988 20.99 21.1588 22 19.9588 22H18.0788C16.9988 22 16.8488 21.54 16.6588 20.97L16.4588 20.37C16.1788 19.55 15.9988 19 14.5588 19H9.43876C7.99876 19 7.78876 19.62 7.53876 20.37L7.33876 20.97C7.14876 21.54 6.99876 22 5.91876 22H4.03876C2.83876 22 1.89876 20.99 2.00876 19.82L2.56876 13.73C2.70876 12.23 2.99876 11 5.61876 11H18.3788C20.9988 11 21.2888 12.23 21.4288 13.73L21.9888 19.82Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 8H3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21 8H20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 3V5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5 5H13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 15H9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15 15H18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
  CustomHomeIcon = `<svg viewBox="0 0 24 24" fill="none">
  <path d="M20 9V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9 22V12H15V22M2 10.6L12 2L22 10.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  CustomProfileIcon = `<svg viewBox="0 0 24 24" fill="none">
<path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  CustomMenuIcon = `<svg viewBox="0 0 24 24" fill="none">
<path d="M3 12H21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 6H21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 18H21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  CustomSortIcon = `<svg viewBox="0 0 24 24" fill="none">
  <path d="M10.4517 6.71997L6.73169 3L3.01172 6.71997" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.73047 21V3" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.5508 17.2793L17.2708 20.9993L20.9908 17.2793" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17.2734 3V21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
  CustomChevronRightIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M9 18L15 12L9 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomChevronUpIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M6 15L12 9L18 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  CustomChevronleftIcon = `<svg viewBox="0 0 24 24" fill="none">
<path d="M15 18L9 12L15 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomChevronDownIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M6 9L12 15L18 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomStarIcon = `<svg viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.1043 4.17505L14.9317 7.8258C15.1108 8.1842 15.4565 8.43272 15.8573 8.49023L19.9453 9.07866C20.9554 9.22449 21.3573 10.4486 20.6263 11.15L17.6702 13.9905C17.3797 14.2698 17.2474 14.6713 17.3162 15.0657L18.0138 19.0759C18.1856 20.0679 17.1298 20.8247 16.227 20.3554L12.5732 18.4607C12.215 18.2749 11.786 18.2749 11.4268 18.4607L7.773 20.3554C6.87023 20.8247 5.81439 20.0679 5.98724 19.0759L6.68385 15.0657C6.75257 14.6713 6.62033 14.2698 6.32982 13.9905L3.37368 11.15C2.64272 10.4486 3.04464 9.22449 4.05466 9.07866L8.14265 8.49023C8.54354 8.43272 8.89028 8.1842 9.06937 7.8258L10.8957 4.17505C11.3477 3.27238 12.6523 3.27238 13.1043 4.17505Z" fill="currentColor"/>
</svg>
`,
  CustomStarOutlineIcon = `<svg width="33" height="33" viewBox="0 0 33 33" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.0184 5.74338L20.531 10.7632C20.7773 11.256 21.2526 11.5977 21.8039 11.6768L27.4248 12.4858C28.8136 12.6864 29.3663 14.3695 28.3612 15.3339L24.2965 19.2396C23.897 19.6237 23.7152 20.1758 23.8097 20.718L24.769 26.232C25.0052 27.596 23.5534 28.6367 22.3121 27.9914L17.2882 25.3862C16.7957 25.1306 16.2058 25.1306 15.7118 25.3862L10.6879 27.9914C9.44657 28.6367 7.99479 27.596 8.23246 26.232L9.19029 20.718C9.28478 20.1758 9.10295 19.6237 8.7035 19.2396L4.63881 15.3339C3.63373 14.3695 4.18638 12.6864 5.57516 12.4858L11.1961 11.6768C11.7474 11.5977 12.2241 11.256 12.4704 10.7632L14.9816 5.74338C15.603 4.50221 17.397 4.50221 18.0184 5.74338Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomDriverIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M16.6848 7.92584C16.6848 7.92584 16.6848 11.656 13.2089 11.656H5.91797C5.91797 11.656 3.03554 11.1473 3.03554 7.92584C3.03554 7.92584 0.862365 6.42534 1.60946 4.35371C2.01597 3.2266 3.11691 2.5 4.31497 2.5H15.5539C16.4212 2.5 17.25 2.88222 17.794 3.55742C18.6675 4.64195 19.3433 6.38403 16.6851 7.92584H16.6848Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M4.25519 7.58203C3.5308 7.58203 2.92751 8.13676 2.86664 8.85863C2.63044 11.6591 2.86444 18.0947 10.01 18.0947C10.01 18.0947 18.7433 18.5905 16.9278 8.71356C16.807 8.05602 16.2277 7.58203 15.5594 7.58203H4.25519Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10"/>
</svg>
`,
  CustomReviewsIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99959 17.5C9.00086 17.5 3.75 13.2486 3.75 8.80274C3.75 5.3222 6.54758 2.5 9.99959 2.5C13.4516 2.5 16.25 5.3222 16.25 8.80274C16.25 13.2486 10.9983 17.5 9.99959 17.5Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.4103 6.09948L11.0871 7.53114C11.1534 7.67169 11.2815 7.76915 11.4299 7.7917L12.944 8.02246C13.3181 8.07965 13.467 8.55969 13.1962 8.83475L12.1014 9.94867C11.9938 10.0582 11.9448 10.2157 11.9702 10.3703L12.2286 11.9429C12.2923 12.332 11.9012 12.6288 11.5669 12.4447L10.2136 11.7017C10.0809 11.6288 9.92205 11.6288 9.789 11.7017L8.43575 12.4447C8.10139 12.6288 7.71034 12.332 7.77435 11.9429L8.03236 10.3703C8.05781 10.2157 8.00883 10.0582 7.90123 9.94867L6.80637 8.83475C6.53564 8.55969 6.6845 8.07965 7.05859 8.02246L8.57265 7.7917C8.72113 7.76915 8.84955 7.67169 8.91588 7.53114L9.59232 6.09948C9.75969 5.74549 10.2429 5.74549 10.4103 6.09948Z" fill="currentColor"/>
</svg>
`,
  CustomSearchIcon = `<svg viewBox="0 0 24 24" fill="none">
<path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.9999 21L16.6499 16.65" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomCloseOutlineIcon = `<svg viewBox="0 0 24 24" fill="none">
<circle cx="12" cy="12" r="11.5" stroke="currentColor"/>
<path d="M15.5 8.5L8.5 15.5" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.5 8.5L15.5 15.5" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomCloseIcon = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M18 6L6 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 6L18 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomCopyIcon = `<svg viewBox="0 0 44 44" fill="none">
<path d="M30 19H21C19.8954 19 19 19.8954 19 21V30C19 31.1046 19.8954 32 21 32H30C31.1046 32 32 31.1046 32 30V21C32 19.8954 31.1046 19 30 19Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 25H14C13.4696 25 12.9609 24.7893 12.5858 24.4142C12.2107 24.0391 12 23.5304 12 23V14C12 13.4696 12.2107 12.9609 12.5858 12.5858C12.9609 12.2107 13.4696 12 14 12H23C23.5304 12 24.0391 12.2107 24.4142 12.5858C24.7893 12.9609 25 13.4696 25 14V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomAddCircleIcon = `<svg viewBox="0 0 26 26" fill="none">
<circle cx="13" cy="13" r="12.75" stroke="currentColor" stroke-width="0.5"/>
<path d="M7 13L19 13" stroke="currentColor" stroke-linecap="round"/>
<path d="M13 7L13 19" stroke="currentColor" stroke-linecap="round"/>
</svg>`,
  CustomAddIcon = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12 5V19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 12H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomRemoveIcon = `<svg viewBox="0 0 26 26" fill="none">
<circle cx="13" cy="13" r="12.75" stroke="currentColor" stroke-width="0.5"/>
<path d="M7 13L19 13" stroke="currentColor" stroke-linecap="round"/>
</svg>`,
  CustomCalendarIcon = `<svg width="33" height="33" viewBox="0 0 33 33" fill="none">
<path d="M11 2.75V6.875" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 2.75V6.875" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.8125 12.499H28.1875" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.875 11.6875V23.375C28.875 27.5 26.8125 30.25 22 30.25H11C6.1875 30.25 4.125 27.5 4.125 23.375V11.6875C4.125 7.5625 6.1875 4.8125 11 4.8125H22C26.8125 4.8125 28.875 7.5625 28.875 11.6875Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomBellIcon = `<svg width="33" height="33" viewBox="0 0 33 33" fill="none">
<path d="M18.8787 28.875C18.637 29.2917 18.29 29.6376 17.8726 29.8781C17.4551 30.1186 16.9818 30.2451 16.5 30.2451C16.0182 30.2451 15.5449 30.1186 15.1274 29.8781C14.71 29.6376 14.363 29.2917 14.1213 28.875M30.25 23.375H2.75C3.84402 23.375 4.89323 22.9404 5.66682 22.1668C6.4404 21.3932 6.875 20.344 6.875 19.25V12.375C6.875 9.82229 7.88906 7.37413 9.6941 5.5691C11.4991 3.76406 13.9473 2.75 16.5 2.75C19.0527 2.75 21.5009 3.76406 23.3059 5.5691C25.1109 7.37413 26.125 9.82229 26.125 12.375V19.25C26.125 20.344 26.5596 21.3932 27.3332 22.1668C28.1068 22.9404 29.156 23.375 30.25 23.375Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomRefreshIcon = `<svg width="45" height="45" viewBox="0 0 45 45" fill="none">
<path d="M28.0873 35.2797C21.1158 38.2807 13.0222 35.0582 10.0212 28.0867C7.02018 21.1152 12.2101 13.8363 12.2101 13.8363M12.2101 13.8363L14.6674 19.5448M12.2101 13.8363L5.89531 16.5546M17.2142 10.0206C24.1857 7.01957 32.2576 10.1915 35.2803 17.2136C38.9065 25.6375 33.6948 32.8659 33.6948 32.8659M33.6948 32.8659L40.0096 30.1476M33.6948 32.8659L31.281 27.2583" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomHelpCircle = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.09 8.99996C9.3251 8.33163 9.78915 7.76807 10.4 7.40909C11.0108 7.05012 11.7289 6.9189 12.4272 7.03867C13.1255 7.15844 13.7588 7.52148 14.2151 8.06349C14.6713 8.60549 14.9211 9.29148 14.92 9.99996C14.92 12 11.92 13 11.92 13" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 17H12.0083" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomReferralIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M8.43839 22V21.0916C8.43839 20.6097 8.24247 20.1476 7.89373 19.8069C7.54499 19.4662 7.07199 19.2748 6.5788 19.2748H2.8596C2.3664 19.2748 1.89341 19.4662 1.54466 19.8069C1.19592 20.1476 1 20.6097 1 21.0916V22M23 10.1755V9.2671C23 8.78526 22.8041 8.32316 22.4553 7.98244C22.1066 7.64173 21.6336 7.45032 21.1404 7.45032H17.4212C16.928 7.45032 16.455 7.64173 16.1063 7.98244C15.7575 8.32316 15.5616 8.78526 15.5616 9.2671V10.1755M4.16946 11.6702C2.97192 8.85039 4.26807 5.59865 7.10827 4.39243C10.5155 2.9454 13.4228 5.06316 13.4228 5.06316M13.4228 5.06316L12.338 2.50903M13.4228 5.06316L11.1547 6.0264M19.0768 11.1978C20.7847 13.6864 20.1391 17.1005 17.5949 18.8466C14.5428 20.9412 11.2987 19.4803 11.2987 19.4803M11.2987 19.4803L12.9544 21.9335M11.2987 19.4803L13.3304 18.0859M6.5788 15.6412C6.5788 16.6446 5.74623 17.458 4.7192 17.458C3.69217 17.458 2.8596 16.6446 2.8596 15.6412C2.8596 14.6379 3.69217 13.8245 4.7192 13.8245C5.74623 13.8245 6.5788 14.6379 6.5788 15.6412ZM21.1404 3.81679C21.1404 4.82017 20.3078 5.63357 19.2808 5.63357C18.2538 5.63357 17.4212 4.82017 17.4212 3.81679C17.4212 2.8134 18.2538 2 19.2808 2C20.3078 2 21.1404 2.8134 21.1404 3.81679Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomInvoiceIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M13.8937 18.5757C14.1698 18.5757 14.3937 18.3519 14.3937 18.0757C14.3937 17.7996 14.1698 17.5757 13.8937 17.5757H6.71707C6.44093 17.5757 6.21707 17.7996 6.21707 18.0757C6.21707 18.3519 6.44093 18.5757 6.71707 18.5757H13.8937ZM13.8937 14.3995C14.1698 14.3995 14.3937 14.1757 14.3937 13.8995C14.3937 13.6234 14.1698 13.3995 13.8937 13.3995H6.71707C6.44093 13.3995 6.21707 13.6234 6.21707 13.8995C6.21707 14.1757 6.44093 14.3995 6.71707 14.3995H13.8937ZM9.45552 10.233C9.73166 10.233 9.95552 10.0092 9.95552 9.73302C9.95552 9.45688 9.73166 9.23302 9.45552 9.23302H6.71707C6.44093 9.23302 6.21707 9.45688 6.21707 9.73302C6.21707 10.0092 6.44093 10.233 6.71707 10.233H9.45552ZM6.44524 5.13923L10.2683 5.13725L12.8927 5.13588L13.7573 5.13543L13.9995 5.1353L14.0633 5.13527L14.0797 5.13526L14.0838 5.13525L14.0849 5.13525L14.0849 5.13525C14.085 5.13525 14.085 5.13525 14.0851 5.13525C14.0851 5.13525 14.0851 5.13525 14.0851 5.13525C15.3558 5.13529 16.3379 5.55008 17.0029 6.23894C17.6701 6.92997 18.0649 7.9453 18.0649 9.23093V18.4043C18.0649 19.6832 17.6731 20.6942 17.0112 21.3844C16.3516 22.0722 15.3782 22.4889 14.12 22.497C14.1196 22.497 14.1191 22.497 14.1186 22.497L10.2953 22.4985L7.6711 22.4995L6.8066 22.4999L6.56442 22.5L6.50062 22.5L6.48426 22.5L6.48012 22.5H6.47908H6.47892C6.47889 22.5 6.47885 22.5 6.47882 22.5C5.20807 22.5 4.22631 22.0852 3.5615 21.3964C2.8946 20.7054 2.5 19.69 2.5 18.4043V9.23093C2.5 7.952 2.89153 6.94123 3.55316 6.25133C4.21253 5.5638 5.18558 5.14732 6.44387 5.13924C6.44432 5.13924 6.44478 5.13924 6.44524 5.13923Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.7291 11.56C19.6401 11.56 21.9999 9.19606 21.9999 6.28C21.9999 3.36394 19.6401 1 16.7291 1C13.8181 1 11.4583 3.36394 11.4583 6.28C11.4583 9.19606 13.8181 11.56 16.7291 11.56Z" fill="currentColor"/>
<path d="M14.9749 7.51018C14.9749 8.1913 15.4967 8.74042 16.145 8.74042H17.468C18.0319 8.74042 18.4905 8.25994 18.4905 7.66858C18.4905 7.02442 18.2111 6.79738 17.7947 6.64954L15.6706 5.91034C15.2542 5.7625 14.9749 5.53546 14.9749 4.8913C14.9749 4.29994 15.4334 3.81946 15.9974 3.81946H17.3204C17.9687 3.81946 18.4905 4.36858 18.4905 5.0497M16.729 3.11206V9.44806" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

`,
  CustomRideIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M13.1565 6.53613H9.84147C8.66564 6.53613 8.40591 7.12169 8.2548 7.83947L7.72119 10.3942H15.2767L14.7431 7.83947C14.592 7.12169 14.3323 6.53613 13.1565 6.53613Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.2175 14.5586C16.2694 15.1111 15.8255 15.588 15.2588 15.588H14.3711C13.8611 15.588 13.7902 15.3708 13.7005 15.1016L13.6061 14.8183C13.4738 14.4311 13.3888 14.1713 12.7088 14.1713H10.2911C9.61107 14.1713 9.5119 14.4641 9.39385 14.8183L9.2994 15.1016C9.20968 15.3708 9.13885 15.588 8.62885 15.588H7.74107C7.1744 15.588 6.73051 15.1111 6.78246 14.5586L7.0469 11.6827C7.11301 10.9744 7.24996 10.3936 8.48718 10.3936H14.5127C15.75 10.3936 15.8869 10.9744 15.953 11.6827L16.2175 14.5586Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.72198 8.97705H7.24976" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.7505 8.97705H15.2783" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.4998 6.61621V7.56066" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.7915 7.56055H12.2082" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.6665 12.2832H10.0832" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.9165 12.2832H14.3332" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.5 10.3715C2.5 13.5776 4.38214 16.6508 6.36653 18.8851C7.36617 20.0106 8.41162 20.946 9.30104 21.6039C9.7452 21.9324 10.158 22.1974 10.5128 22.3827C10.8433 22.5554 11.1979 22.6998 11.4994 22.6998C11.801 22.6998 12.1556 22.5554 12.4862 22.3827C12.841 22.1974 13.2538 21.9325 13.698 21.6039C14.5875 20.946 15.6331 20.0106 16.6329 18.8851C18.6176 16.6509 20.5 13.5776 20.5 10.3715C20.5 5.36581 16.4743 1.2998 11.4994 1.2998C6.52455 1.2998 2.5 5.36589 2.5 10.3715Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomPickupIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<circle cx="12" cy="12" r="5" fill="currentColor"/>
</svg>
`,
  CustomDropoffIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<circle opacity="0.3" cx="12" cy="12" r="8.5" fill="currentColor" stroke="black"/>
<circle cx="12" cy="12" r="5" fill="currentColor"/>
</svg>
`,
  CustomInfoIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 16V12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 8H12.01" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomMinusIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M5 12H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomDotsIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomCircleicon = `<svg width="12" height="13" viewBox="0 0 12 13" fill="none">
<circle cx="6" cy="6.5" r="6" fill="currentColor"/>
</svg>
`,
  CustomNotificationPickupIcon = `<svg width="20" height="21" viewBox="0 0 20 21" fill="none">
<g clip-path="url(#clip0_10159_5035)">
<path d="M6.25 16.75V12.1662M6.25 12.1662V2.86363C6.25 2.17181 6.98862 1.73064 7.59775 2.05863L15.4714 6.2983C16.1351 6.65568 16.1055 7.61754 15.4211 7.93343L6.25 12.1662Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.25 19.6667C6.94036 19.6667 7.5 19.107 7.5 18.4167C7.5 17.7263 6.94036 17.1667 6.25 17.1667C5.55964 17.1667 5 17.7263 5 18.4167C5 19.107 5.55964 19.6667 6.25 19.6667Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_10159_5035">
<rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
`,
  CustomFilterIcon = `
<svg width="18" height="20" viewBox="0 0 18 20" fill="none">
<path d="M11.0865 11.667V17.173C11.0865 17.6041 10.8328 17.9899 10.4501 18.1405L8.26529 19.0006C7.62593 19.2523 6.94498 18.7533 6.94498 18.0331V11.6685C6.94498 11.4604 6.88539 11.2571 6.77402 11.0853L1.23928 2.54912C0.794935 1.86379 1.2599 0.933334 2.04671 0.933334H15.9533C16.7389 0.933334 17.2041 1.86139 16.7621 2.54692L11.2561 11.0861C11.1456 11.2574 11.0865 11.4598 11.0865 11.667Z" stroke="currentColor"/>
</svg>
`,
  CustomDollarIcon = `<svg width="33" height="33" viewBox="0 0 33 33" fill="none">
<path d="M8.25 22.3736C8.25 25.6254 10.699 28.2471 13.7418 28.2471H19.9509C22.5978 28.2471 24.75 25.9531 24.75 23.1298C24.75 20.0544 23.4389 18.9704 21.4846 18.2646L11.5154 14.7354C9.56109 14.0296 8.25 12.9456 8.25 9.87022C8.25 7.04689 10.4022 4.75293 13.0491 4.75293H19.2582C22.301 4.75293 24.75 7.3746 24.75 10.6265" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.4844 1.375V31.625" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomChatIcon = `<svg width="60" height="60" viewBox="0 0 60 60" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.858 5.00039C38.6328 4.96493 46.7861 9.51778 51.3487 17.0011C55.9114 24.4843 56.2177 33.806 52.1561 41.5718L51.6553 42.548C51.2448 43.3161 51.1589 44.2162 51.4168 45.0479C52.1314 46.9461 52.7288 48.8863 53.2054 50.8575C53.2054 51.8575 52.9192 52.4289 51.8461 52.4051C49.9453 51.9853 48.0733 51.4445 46.2417 50.786C45.4528 50.5688 44.6141 50.619 43.8569 50.9289C43.1653 51.2622 41.7582 52.1194 41.7105 52.1194C32.5212 56.9513 21.298 55.6182 13.5006 48.7687C5.70323 41.9192 2.95029 30.9751 6.58098 21.2603C10.2117 11.5454 19.4723 5.07651 29.858 5.00039V5.00039Z" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<ellipse cx="1.19241" cy="1.19047" rx="1.19241" ry="1.19047" transform="matrix(-1 0 0 1 31.0504 28.8099)" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30 32.5002C31.3807 32.5002 32.5 31.3809 32.5 30.0002C32.5 28.6195 31.3807 27.5002 30 27.5002C28.6193 27.5002 27.5 28.6195 27.5 30.0002C27.5 31.3809 28.6193 32.5002 30 32.5002Z" fill="currentColor"/>
<path d="M42.5 32.5002C43.8807 32.5002 45 31.3809 45 30.0002C45 28.6195 43.8807 27.5002 42.5 27.5002C41.1193 27.5002 40 28.6195 40 30.0002C40 31.3809 41.1193 32.5002 42.5 32.5002Z" fill="currentColor"/>
<path d="M17.5 32.5002C18.8807 32.5002 20 31.3809 20 30.0002C20 28.6195 18.8807 27.5002 17.5 27.5002C16.1193 27.5002 15 28.6195 15 30.0002C15 31.3809 16.1193 32.5002 17.5 32.5002Z" fill="currentColor"/>
</svg>
`,
  CustomMailIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M18.0713 8.88824L13.5814 12.5245C12.7317 13.1946 11.5405 13.1946 10.6908 12.5245L6.16235 8.88824" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.08342 3H16.8885C18.3023 3.01603 19.6479 3.62039 20.612 4.67232C21.576 5.72425 22.0744 7.13193 21.991 8.56751V15.4325C22.0744 16.8681 21.576 18.2758 20.612 19.3277C19.6479 20.3796 18.3023 20.984 16.8885 21H7.08342C4.0467 21 2 18.5019 2 15.4325V8.56751C2 5.49812 4.0467 3 7.08342 3Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomCallingIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M18.5 9C18.5 8.4 18.03 7.48 17.33 6.73C16.69 6.04 15.84 5.5 15 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 9C22 5.13 18.87 2 15 2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  CustomClipIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M14.1666 7.77972L8.04985 13.9079C7.81252 14.1481 7.52937 14.339 7.21694 14.4693C6.90452 14.5995 6.56909 14.6666 6.23026 14.6666C5.89143 14.6666 5.55599 14.5995 5.24357 14.4693C4.93114 14.339 4.64799 14.1481 4.41066 13.9079L2.58078 12.0592C2.10175 11.5758 1.83325 10.9247 1.83325 10.2463C1.83325 9.56794 2.10175 8.91678 2.58078 8.43339L9.09844 1.93755C9.28957 1.74608 9.51697 1.59412 9.76752 1.49041C10.0181 1.38671 10.2868 1.33331 10.5582 1.33331C10.8297 1.33331 11.0984 1.38671 11.3489 1.49041C11.5995 1.59412 11.8269 1.74608 12.018 1.93755L12.7479 2.66271C12.9406 2.85261 13.0936 3.07853 13.198 3.32746C13.3023 3.57638 13.3561 3.84338 13.3561 4.11304C13.3561 4.3827 13.3023 4.6497 13.198 4.89862C13.0936 5.14755 12.9406 5.37347 12.7479 5.56337L6.97043 11.3238C6.87486 11.4196 6.76116 11.4955 6.63589 11.5474C6.51061 11.5993 6.37625 11.626 6.24054 11.626C6.10482 11.626 5.97046 11.5993 5.84518 11.5474C5.71991 11.4955 5.60621 11.4196 5.51064 11.3238L5.15083 10.9561C5.05448 10.8612 4.978 10.7482 4.92581 10.6238C4.87361 10.4993 4.84674 10.3658 4.84674 10.231C4.84674 10.0961 4.87361 9.96265 4.92581 9.83819C4.978 9.71373 5.05448 9.60076 5.15083 9.50582L9.02648 5.68593" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
}
