import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomMatIcon } from '@models/custom-mat-icon.model';
import { MatBadgeModule } from '@angular/material/badge';
import { NgClass } from '@angular/common';

export interface CustomMatIconBadgeProps {
  content: string;
  color: 'primary' | 'accent' | 'warn';
  size: 'small' | 'medium' | 'large';
}

@Component({
  selector: 'app-custom-mat-icon',
  templateUrl: './custom-mat-icon.component.html',
  styleUrls: ['./custom-mat-icon.component.scss'],
  standalone: true,
  imports: [MatIconModule, MatBadgeModule, NgClass],
})
export class CustomMatIconComponent implements OnInit {
  @Input({ required: true }) iconName: string = '';
  @Input({ required: true }) icon: keyof typeof CustomMatIcon = 'CustomStarIcon';
  @Input() badge?: CustomMatIconBadgeProps;
  @Input() buttonSize = 24;

  iconInitialized = false;

  constructor(
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private readonly host: ElementRef<HTMLElement>
  ) {}

  ngOnInit() {
    this.host.nativeElement.style.setProperty('--button-size', `${this.buttonSize}px`);
    this.iconRegistry.addSvgIconLiteral(this.iconName, this.sanitizer.bypassSecurityTrustHtml(CustomMatIcon[this.icon]));
    this.iconInitialized = true;
  }
}
